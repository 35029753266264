import ApiHelper from './index'

const uploadFiles = async (payload, stateObj = {}, fileName = '') => {
  return await ApiHelper.upload('file_management/files', payload, stateObj, fileName)
}

const getFiles = async (params) => {
  return await ApiHelper.get('file_management/v2/files', params)
}

const getFileOptions = async () => {
  return await ApiHelper.get('file_management/file_options')
}

const retrieveFile = async (id) => {
  return await ApiHelper.get(`file_management/file/${id}`)
}

const deleteFile = async (id) => {
  return await ApiHelper.remove(`file_management/file/${id}`)
}

const updateFile = async (id, payload) => {
  return await ApiHelper.patch(`file_management/file/${id}`, payload)
}

const getAllTags = async () => {
  return await ApiHelper.get('file_management/tags/')
}

const deleteTag = async (id) => {
  return await ApiHelper.remove(`file_management/tag/${id}`)
}

const addTag = async (payload) => {
  return await ApiHelper.post('file_management/tags/', payload)
}

const associateTags = async (id, payload) => {
  return await ApiHelper.patch(`file_management/patch_tag_to_file/${id}`, payload)
}

const createVideoFileRecord = async (payload) => {
  return await ApiHelper.post(`file_management/create_file_record/`, payload)
}

const getFilePresignedUrl = async (payload) => {
  return await ApiHelper.post(`file_management/presigned-url/`, payload)
}

const getFileStatuses = async (params) => {
  return await ApiHelper.get(`file_management/files/status`, params)
}

const getPresignedUploadUrl = async (payload) => {
  return await ApiHelper.post(`file_management/post-presign-url/`, payload)
}

const createFile = async (payload, version = 'v2') => {
  return await ApiHelper.post(`file_management/${version}/file/`, payload)
}

const transformFile = async (payload) => {
  return await ApiHelper.post('file_management/video/transform/', payload)
}

export default {
  uploadFiles,
  retrieveFile,
  createFile,
  getFiles,
  getFileOptions,
  deleteFile,
  updateFile,
  getAllTags,
  addTag,
  associateTags,
  createVideoFileRecord,
  deleteTag,
  getFilePresignedUrl,
  getFileStatuses,
  getPresignedUploadUrl,
  transformFile
}
