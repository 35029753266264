import { defineStore } from 'pinia'
import FileManagementService from 'src/services/files'
import AuthService from 'src/services/auth'
import { dateFormat, formatDate, encodeS3URI } from 'src/utils/helpers'
import { useUserStore } from './user'
import { useAuthStore } from './auth'
import { processStatus } from 'src/components/User/StationBalancing/config'

export const useFileStore = defineStore('file', {
  state: () => ({
    files: [],
    totalFilesCount: 0,
    isFetchingFiles: false,
    fileOptions: [],
    tagsList: [],
    presignedUrl: '',
    filesInProgress: [],
    dummyFile: {
      id: 0,
      is_deleted: false,
      deleted_at: null,
      name: 'spring_assembly.mp4',
      description: null,
      created: '08/23/2023',
      location: 'file_manager/clamp-lamp-assembly__2020-12-1714-52-10.mp4',
      meta: {
        fps: 30,
        size: 40158898,
        type: 'video',
        codec: 'h264',
        duration: '103.500000'
      },
      videoUrl:
        'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/clamp-lamp-assembly__2020-12-1714-52-10.mp4',
      thumbnail:
        'https://kaizen-videos-thumbnails.s3.us-east-2.amazonaws.com/clamp-lamp-assembly__2020-12-1714-52-10.jpg',
      created_by: 58,
      deleted_by: null,
      organization: 'retrocausal',
      folder: null,
      process: null,
      tag: [],
      key: 45,
      type: 'video',
      owner: 'Andrey Konin'
    }
  }),

  getters: {
    filesList() {
      const { userIdToNameMap } = useUserStore()
      return this.files.map((file) => ({
        ...file,
        key: file.id,
        name: file.name,
        type: file.meta?.type,
        created: formatDate(file.created, dateFormat),
        tag: file.tag.map((t) => t.id),
        owner: userIdToNameMap[file.created_by] || '-',
        status:
          file.process?.status?.name === processStatus.Finished
            ? 'Uploaded'
            : 'Failed'
      }))
    },

    tagsIdToNameMap() {
      const tagDict = this.tagsList?.reduce((res, tag) => {
        res[tag.id] = tag.name
        return res
      }, {})
      return tagDict
    },

    tagOptions() {
      return this.tagsList?.map((tag) => ({
        label: tag.name,
        value: tag.id
      }))
    }
  },

  actions: {
    setDummyFile() {
      let dummyFile = this.dummyFile
      if (!this.files.length || !this.files[0].id == 0) {
        this.files.unshift(dummyFile)
      }
      console.log('this.files: ', this.files)
    },

    removeDummyElement() {
      this.files = this.files.filter((file) => file.id !== 0)
    },

    setIsFetchingFiles(value) {
      this.isFetchingFiles = value
    },

    async uploadFiles(payload, stateObj = {}, fileName = '') {
      const res = await FileManagementService.uploadFiles(payload, stateObj, fileName)
      return res
    },

    async fetchFiles(params, loading = true) {
      if (loading) this.isFetchingFiles = true

      const [err, data] = await FileManagementService.getFiles(params)
      this.isFetchingFiles = false
      if (err) {
        console.log(err)
        return null
      }

      this.files = data.results?.map((file) => {
        const urlParts = file.thumbnail.split('/')
        return {
          ...file,
          thumbnail: urlParts.shift() + '/' + encodeS3URI(urlParts.join('/'))
        }
      })
      this.totalFilesCount = data.count
    },

    async fetchFilesInProgress() {
      const [error, listData] = await FileManagementService.getFileStatuses({
        status: 'in_progress'
      })
      if (error) return

      const previousFileInProgress = this.filesInProgress
      this.filesInProgress = listData?.results

      // Check if any file from the previous list is missing in the current list
      const hasFileProcessed = previousFileInProgress.some(
        (file) => !this.filesInProgress.find((currentFile) => currentFile.id === file.id)
      )

      return hasFileProcessed
    },

    async fetchFileOptions() {
      const [error, data] = await FileManagementService.getFileOptions()
      if (error) {
        console.log('error:', error)
        return
      }
      this.fileOptions = data
        .map((el) => ({
          value: el.id,
          label: el.name,
          thumbnail: el.thumbnail,
          location: el.location
        }))
        .sort((a, b) => b.value - a.value)
    },

    async retrieveFile(id) {
      const [error, data] = await FileManagementService.retrieveFile(id)
      if (error) {
        console.log(error)
        return 0
      }
      return data
    },

    async updateFile(id, payload) {
      const [error] = await FileManagementService.updateFile(id, payload)

      if (error) {
        console.log(error)
        return { error: error }
      }
      const temp = [...this.files]
      const index = temp.findIndex((f) => f.id === id)
      temp[index] = {
        ...temp[index],
        ...payload
      }
      this.files = temp
      return { success: true }
    },

    async updateFileInList(id, payload) {
      const temp = [...this.files]
      const index = temp.findIndex((f) => f.id === id)
      temp[index] = {
        ...temp[index],
        ...payload
      }
      this.files = temp
    },

    async deleteFile(id) {
      const [error] = await FileManagementService.deleteFile(id)
      return error
    },

    async fetchFileUrl({ id, location }) {
      const temp = [...this.files]
      const fileIndex = temp.findIndex((file) => file.id === id)
      const file = temp[fileIndex]
      if (file && file.fileUrl) return file.fileUrl

      const { organization } = useAuthStore()
      const bucketName = `${organization}-kaizen`

      const [error, data] = await FileManagementService.getFilePresignedUrl({
        bucket_name: bucketName,
        object_path: location
      })
      if (error) {
        console.log('Error:', error)
        return 0
      }

      temp[fileIndex] = {
        ...temp[fileIndex],
        fileUrl: data.presigned_url
      }
      this.files = temp

      return data.presigned_url
    },

    async createVideoFileRecord(payload) {
      return await FileManagementService.createVideoFileRecord(payload)
    },

    async fetchPresignedUrl(location) {
      const { organization } = useAuthStore()
      const bucketName = `${organization}-kaizen`
      const [error, data] = await AuthService.getPresignedUrl({
        bucket_name: bucketName,
        object_path: location
      })

      if (error) {
        console.log('Error:', error)
        return
      }
      this.presignedUrl = data.presigned_url
      return data.presigned_url
    },

    async deleteTag(tagId) {
      const [error, data] = await FileManagementService.deleteTag(tagId)
      if (error) {
        console.log('error ->', error)
        return
      }
      this.tagsList = this.tagsList.filter((data) => data.id != tagId)
    },

    async getTags() {
      const [error, data] = await FileManagementService.getAllTags()
      if (error) {
        console.log('error ->', error)
        return
      }
      this.tagsList = data
    },

    async addTag(payload) {
      const [error, data] = await FileManagementService.addTag(payload)
      if (error) {
        console.log('error ->', error)
        return
      }
      this.tagsList = [...this.tagsList, data]
    },

    async associateTagToFile(id, payload) {
      const [error, data] = await FileManagementService.associateTags(id, payload)
      if (error) {
        console.log('error ->', error)
        return
      }
      const temp = [...this.files]
      const index = temp.findIndex((file) => file.id === id)
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          tag: [...data.tag]
        }
      }
      this.files = temp
    },

    async associateDummyTag(id = 0) {
      const temp = [...this.files]
      const index = temp.findIndex((file) => file.id === id)
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          tag: [
            {
              id: 0,
              name: 'Line 01',
              description: 'default',
              created_by: 58,
              organization: 'retrocausal'
            }
          ]
        }
      }
      this.files = temp
    },

    async generatePresignedUploadURL(payload) {
      const [error, data] = await FileManagementService.getPresignedUploadUrl(payload)
      return error ? null : data
    },

    async createFile(payload) {
      const [error, data] = await FileManagementService.createFile(payload)
      return error ? null : data
    }
  }
})
