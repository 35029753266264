<template>
  <a-modal id="suggestion-question-modal" v-model:open="showConfirmModal">
    <p class="px-2 py-3">
      Do you want to answer a few questions and generate improvement recommendation?
    </p>
    <template #footer>
      <a-space class="d-flex w-100">
        <a-button @click="showConfirmModal = false"> No </a-button>
        <a-button type="primary" @click="handleConfirmImprovement"> Yes </a-button>
      </a-space>
    </template>
  </a-modal>

  <div
    v-if="loading && !isDemoOngoing"
    class="h-100 d-flex align-items-center justify-content-center"
  >
    <a-spin size="large" />
  </div>

  <div v-else id="sd-result-time-and-motion-study" class="p-4">
    <!-- Cycle details -->
    <a-row>
      <a-col :span="workCycles.length > 8 ? 20 : 14">
        <a-typography-title :level="4" class="mb-2">Time and Motion Study Result</a-typography-title>
        <a-table
          id="cycle-result-table"
          :bordered="true"
          :title="null"
          :dataSource="[cyclesTableData]"
          :columns="cyclesTableColumn"
          size="small"
          class="mb-3"
          :scroll="{ x: true }"
          :pagination="false"
        ></a-table>
      </a-col>
      <a-col :span="workCycles.length > 8 ? 4 : 10" class="text-end">
        <a-space>
          <a-button
            type="primary"
            :loading="isDownloading"
            @click="downloadExcelSheet"
            class="d-flex align-items-center justify-content-center"
            style="display: flex; align-items: center"
          >
            Work Content
            <template #icon>
              <DownloadOutlined />
            </template>
          </a-button>
        </a-space>
      </a-col>
    </a-row>

    <!-- Charts -->
    <a-row :gutter="[24, 24]" class="mt-2">
      <a-col span="12">
        <a-card id="step-times-chart" title="Yamazumi Chart" size="small" hoverable>
          <BarChart
            :title="'Bar chart'"
            :data="!isDemoOngoing ? stepsTimePerCycle : stationDesignDummyObject.stepsTimePerCycle"
          />
        </a-card>
      </a-col>
      <a-col span="12">
        <a-card id="value-pie-chart" title="Value Category" size="small" hoverable>
          <PieChart
            :valueTypeData="
              !isDemoOngoing ? valueAddedPieChart : stationDesignDummyObject.valueAddedPieChart
            "
          />
        </a-card>
      </a-col>
      <a-col span="24">
        <StepsTable />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { useStationStore } from 'src/stores/station'
import { useClassificationStore } from 'src/stores/classification'
import { useSegmentationStore } from 'src/stores/segmentation'
import { DownloadOutlined } from '@ant-design/icons-vue'
import { useToast } from 'vue-toastification'
import { downloadS3File } from 'src/utils/helpers'
import { cyclesColumn } from '../config'
import BarChart from './BarChart.vue'
import PieChart from './PieChart.vue'
import StepsTable from './StepsTable.vue'
import eventBus from 'src/utils/eventBus'
import ExcelService from 'src/services/excel'

const toast = useToast()

export default {
  inject: ['nextTab', 'showError', 'showInfo'],
  components: {
    BarChart,
    PieChart,
    StepsTable,
    DownloadOutlined
  },

  data() {
    return {
      loading: true,
      showConfirmModal: false,
      isDownloading: false
    }
  },

  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationStore, [
      'newStudy',
      'newStudyId',
      'stationDesignDummyObject',
      'workCycles',
      'masterCycle',
      'studyVideoUrl'
    ]),
    ...mapState(useSegmentationStore, [
      'valueAddedPieChart',
      'stepsTimePerCycle',
      'cyclesTableData'
    ]),
    ...mapState(useClassificationStore, ['therbligs']),

    cyclesTableColumn() {
      let columns = cyclesColumn
      if (this.workCycles.length < 2) return columns
      const cycleTimeColumns = Array.from({ length: this.workCycles.length }).map((v, ix) => ({
        title: ix + 1,
        key: `cycle_time_${ix + 1}`,
        dataIndex: `cycle_time_${ix + 1}`,
        align: 'center',
        width: 60
      }))
      columns = columns.map((col) =>
        col.key === 'observedTime' ? { ...col, children: cycleTimeColumns } : col
      )
      return columns
    }
  },

  watch: {
    // masterCycle(cycle) {
    //   if (!cycle || !Object.keys(cycle)?.length) return
    //   this.getCycleSegments(cycle.cycle_no)
    // }
  },

  methods: {
    ...mapActions(useStationStore, ['fetchWorkCyles', 'updateStudy']),
    ...mapActions(useSegmentationStore, ['fetchAllSteps', 'getCycleSegments']),
    ...mapActions(useClassificationStore, ['getTherbligs']),

    async getData() {
      let promises = []
      promises.push(this.fetchWorkCyles())
      promises.push(this.getTherbligs())
      await Promise.all(promises)
      await this.fetchAllSteps()
    },

    handleShowImprovementConfirmModal() {
      if (this.loading) return
      if (!this.newStudy.get_improvement) this.showConfirmModal = true
      else this.nextTab()
    },

    async handleConfirmImprovement() {
      await this.updateStudy({ get_improvement: true })
      this.nextTab()
    },

    async downloadExcelSheet() {
      this.isDownloading = true
      try {
        const [error, data] = await ExcelService.getStationDesignSheet(this.newStudyId)
        if (error || !data.data) throw new Error('Invalid response from API')
        else await downloadS3File(data.data)
      } catch (error) {
        toast.error('Something went wrong, Please try later.')
      } finally {
        this.isDownloading = false
      }
    }
  },
  async created() {
    eventBus.$on('timeStudy-next-page', this.handleShowImprovementConfirmModal)
    await this.getData()
    this.loading = false
  },
  beforeUnmount() {
    eventBus.$off('timeStudy-next-page', this.handleShowImprovementConfirmModal)
  }
}
</script>
